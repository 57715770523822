import api from './axiosConfig';

export interface Transaction {
  transactionAmount: number;
  cardAcceptorInfo: string;
  totalAssignedCashback: number;
  customerAssignedCashback: number;
  merchantName: string;
  reportedMerchantCvr: string;
  reportedTransactionTime: string;
  cashbackAssignmentTimeUTC: string;
}

export interface PaginationRequest {
  pageNumber: number;
  pageSize: number;
}

interface TransactionStats {
  totalTransactionAmount: number;
  totalAssignedCashback: number;
}

interface TransactionResponse {
  data: PaginatedResponse<Transaction>;
  stats: TransactionStats;
}

export interface PaginatedResponse<T> {
  pageNumber: number;
  pageSize: number;
  total: number;
  data: T[];
}

export const listTransactions = async (pagination: PaginationRequest): Promise<TransactionResponse> => {
  try {
    const response = await api.post('/business-account/list-transactions', {
      pagination
    });
    return response.data;
  } catch (error) {
    console.error('Listing transactions error:', error);
    throw error;
  }
};
