import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { resetPassword } from '../api/auth';
import { TextField } from './TextField';
import { Button } from './Button';
import { ROUTES } from '../config/routes';

const ResetPasswordForm: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    } else {
      setError('Error while loading page');
    }
  }, [location]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await resetPassword(token, password);
      navigate('/login?resetSuccess');
    } catch (error) {
      console.error('Password reset error:', error);
      setError('Failed to reset password. Please try again.');
    }
  };

  if (error) {
    return (
      <div className="container h-screen flex flex-col gap-4 items-center justify-center text-red">
        {error}
        <Link to={ROUTES.LOGIN()}>
          <Button>Back to Login</Button>
        </Link>
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white rounded-3xl w-full p-4 md:max-w-[424px]"
    >
      <h2 className="text-primary text-center font-semibold mb-6">
        Password Reset
      </h2>
      <TextField
        type="password"
        label="New Password"
        className="mb-4"
        value={password}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setPassword(e.target.value)
        }
        required
      />
      <TextField
        type="password"
        label="New Password Confirmation"
        value={confirmPassword}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setConfirmPassword(e.target.value)
        }
        className="mb-24"
        required
      />
      <Button type="submit" size="full">
        Save
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
