import api from './axiosConfig';

export interface PaymentProvider {
    id: string;
    name: string;
  }

  export const listPaymentProviders = async (): Promise<PaymentProvider[]> => {
    try {
      const response = await api.post('/business/list-payment-providers', {});
      return response.data;
    } catch (error) {
      console.error('Listing payment providers error:', error);
      throw error;
    }
  };