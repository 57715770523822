import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getTermsOfService,
  TermsOfService as TermsOfServiceType,
  acceptTermsOfService,
} from "../api/termsOfService";
import { Button } from "../components/Button";
import { Loader } from "../components/Loader";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useAuth } from '../context/AuthContext';

type ParsedTerms = TermsOfServiceType & {
  parsedContent: any;
};

export const TermsOfService = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [terms, setTerms] = useState<ParsedTerms | null>(null);
  const [accepted, setAccepted] = useState(false);
  const navigate = useNavigate();
  const { checkTermsAcceptance } = useAuth();

  useEffect(() => {
    fetchTerms();
  }, []);

  const fetchTerms = async () => {
    try {
      const response = await getTermsOfService();
      setAccepted(response.accepted);
      
      if (!response.termsOfService) {
        setTerms(null);
        return;
      }
      
      const parsedContent = typeof response.termsOfService.content === 'string' 
        ? JSON.parse(response.termsOfService.content)
        : response.termsOfService.content;
      
      setTerms({
        ...response.termsOfService,
        content: response.termsOfService.content,
        parsedContent
      });
    } catch (err) {
      setError("Failed to fetch terms of service");
      console.error("Error fetching terms of service:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptTerms = async () => {
    try {
      await acceptTermsOfService(terms?.version || 0);
      const response = await getTermsOfService();
      setAccepted(response.accepted);
      
      if (response.accepted) {
        await checkTermsAcceptance();
        navigate("/");
      }
    } catch (err) {
      setError("Failed to accept terms of service");
      console.error("Error accepting terms of service:", err);
    }
  };

  if (loading) {
    return (
      <div className="loading-page">
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col w-full gap-4 bg-white rounded-3xl p-4 md:max-w-[624px]">
        <div className="text-red-500 text-center">{error}</div>
      </div>
    );
  }

  if (!terms) {
    return (
      <div className="flex flex-col w-full gap-4 bg-white rounded-3xl p-4 md:max-w-[624px]">
        <div className="text-center text-gray-500">
          Terms of service not added yet
        </div>
      </div>
    );
  }

  return (
    <>
      {!accepted && (
        <div className="w-full bg-yellow-100 border-l-4 border-yellow-500 p-4 mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Please review and accept the Terms of Service to continue.
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col w-full gap-4 bg-white rounded-3xl p-4 md:max-w-[900px]">
        
        <div className="prose prose-ol:list-decimal prose-ul:list-disc max-w-none mb-6">
          {documentToReactComponents(terms.parsedContent)}
        </div>
        <div className="text-sm text-gray-500 mb-6 text-center">
          Version: {terms.version}
        </div>
        {!accepted && (
          <Button onClick={handleAcceptTerms} size="lg">
            Accept Terms of Service
          </Button>
        )}
      </div>
    </>
  );
};

export default TermsOfService;
