import api from './axiosConfig';

export interface BusinessCategory {
    id: string;
    name: string;
  }

  export const listBusinessCategories = async (): Promise<BusinessCategory[]> => {
    try {
      const response = await api.post('/business/list-business-categories', {});
      return response.data;
    } catch (error) {
      console.error('Listing business categories error:', error);
      throw error;
    }
  };