import React, { ChangeEvent, FC, ReactNode, useState } from 'react';
import { ImageDropzone } from './ImageDropzone';
import { Image } from '../types';
import { ReactComponent as DeleteIcon } from '../assets/icons/x.svg';
import { Button } from './Button';
import { cn } from '../helpers/cn';
import { Loader } from './Loader';

export type ImageUploadProps = {
  label: ReactNode;
  images?: Image[];
  multiple?: boolean;
  onDelete?: (id: string) => void;
  onChange?: (file: File | null) => void;
};

export const ImageUpload: FC<ImageUploadProps> = ({
  label,
  images,
  multiple,
  onDelete,
  onChange,
}) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = (id: string) => () => {
    onDelete?.(id);
  };

  const handleChange = async (file: File | null) => {
    try {
      setLoading(true);
      await onChange?.(file);
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <div>
      <span className="text-sm mb-0.5">{label}</span>
      <div
        className={cn(
          'border border-dashed rounded-2xl p-2 border-gray-300 min-h-[10.5rem] flex flex-col w-full gap-2',
          {
            'items-center justify-center p-4': Boolean(images) || loading,
          }
        )}
      >
        {!loading ? (
          <>
            {(!images || images.length === 0) && (
              <ImageDropzone
                className="flex-1"
                onChange={handleChange}
                disabled={loading}
              />
            )}
            {images && images.length > 0 && (
              <div className="flex gap-4 flex-wrap">
                {images.map((image) => (
                  <div key={image.id} className="flex flex-col items-center">
                    <div className="relative ">
                      <img
                        src={image.url}
                        alt={image.title}
                        className="object-cover max-h-24 max-w-24 w-full h-full rounded-md"
                      />

                      <Button
                        variant="icon"
                        onClick={handleDelete(image.id)}
                        className="absolute -top-4 -right-3 -z-0 "
                      >
                        <DeleteIcon className="w-3 h-3" />
                      </Button>
                    </div>
                    {!multiple && (
                      <label className="text-primary underline cursor-pointer bottom-0 text-sm">
                        Choose another file
                        <input
                          type="file"
                          className="hidden"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleDelete(image.id);
                            handleChange(e.target.files?.[0] || null);
                          }}
                          accept="image/*"
                        />
                      </label>
                    )}
                  </div>
                ))}
              </div>
            )}

            {multiple && images && images?.length > 0 && images.length < 10 && (
              <label className="text-primary underline cursor-pointer self-start text-sm">
                Add another file
                <input
                  type="file"
                  className="hidden "
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChange(e.target.files?.[0] || null)
                  }
                  accept="image/*"
                />
              </label>
            )}
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
    </div>
  );
};
