import React, { FC, useState } from 'react';
import Dropzone, { DropzoneState } from 'shadcn-dropzone';
import { cn } from '../helpers/cn';
import { ReactComponent as Placeholder } from '../assets/icons/image.svg';

type ImageUploadProps = {
  className?: string;
  onChange?: (file: File | null) => void;
  disabled?: boolean;
};

export const ImageDropzone: FC<ImageUploadProps> = ({
  className,
  onChange,
  disabled,
}) => {
  return (
    <div className={cn('flex w-full', className)}>
      <Dropzone
        dropZoneClassName={cn('h-full border-0')}
        containerClassName="w-full"
        disabled={disabled}
        multiple={false}
        accept={{
          'image/*': ['.png', '.jpg'],
        }}
        maxSize={1024 * 1024 * 5}
        onDrop={async (accepetedFiled: File[]) => {
          const file = accepetedFiled[0];
          if (file) {
            onChange?.(file);
          }
        }}
      >
        {(dropzone: DropzoneState) => (
          <>
            {dropzone.isDragAccept ? (
              <div className="text-sm font-medium">Drop your files here!</div>
            ) : (
              <div className="flex flex-col items-center justify-center gap-2 ">
                <Placeholder />
                <p className="text-sm text-black/90">
                  Drag and Drop file here or{' '}
                  <span className="text-primary-500 underline">
                    Choose file
                  </span>
                </p>
                <p className="text-black/60 text-sm">PNG, JPG (Max 5MB)</p>
              </div>
            )}

            {dropzone.fileRejections && dropzone.fileRejections.length > 0 && (
              <div className="text-red-500">
                {dropzone.fileRejections[0]?.errors?.[0]?.message}
              </div>
            )}
          </>
        )}
      </Dropzone>
    </div>
  );
};
