import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '../helpers/cn';

const button = cva(
  'flex items-center px-4 py-[0.9375rem] justify-center rounded-full font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        primary: 'bg-primary text-white hover:bg-primary-500',
        secondary: 'hover:bg-primary-300 hover:text-primary h-10',
        light: 'bg-primary/10 text-primary hover:bg-primary-400',
        ghost: 'bg-transparent',
        outline: 'border border-gray-300 hover:bg-gray-100 text-primary',
        icon: 'bg-gray-100 hover:bg-gray-400 rounded-full w-fit !py-2 !px-2 !h-fit',
      },
      size: {
        sm: 'h-8 px-3 text-sm',
        md: 'h-10 px-4 text-base',
        lg: 'h-12 px-10',
        full: 'w-full',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
);

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof button> & {
    isLoading?: boolean;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'primary',
      size = 'md',
      isLoading,
      disabled,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={cn(
          button({
            variant,
            size,
          }),
          className
        )}
        disabled={isLoading || disabled}
        {...props}
      >
        {isLoading ? (
          <span className="mr-2 h-4 w-4 animate-spin rounded-full flex-shrink-0 border-2 border-gray-200 border-t-white" />
        ) : null}
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';
