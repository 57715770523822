import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Loader } from './Loader';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../config/routes';
import { AppLayout } from './layouts/AppLayout';

export const ProtectedRouts = () => {
  const { loading, user, termsAccepted } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  if (!user) {
    return <Navigate to={ROUTES.LOGIN()} />;
  }

  if (termsAccepted === false && location.pathname !== ROUTES.TERMS_OF_SERVICE()) {
    return <Navigate to={ROUTES.TERMS_OF_SERVICE()} replace />;
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
}; 