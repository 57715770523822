import React, { ReactNode } from 'react';
import { cn } from '../helpers/cn';

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  label?: string | ReactNode;
  error?: string;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, error, name, disabled, label, ...rest }, ref) => (
    <>
      <label
        className={cn('flex cursor-pointer items-center ', {
          'cursor-not-allowed text-opacity-60': disabled,
        })}
      >
        <input
          disabled={disabled}
          id={name}
          type="checkbox"
          className={cn(
            className,
            'relative h-6 w-6 appearance-none rounded-md border border-black/20 bg-transparent file:mr-2 focus:ring-1 focus:ring-offset-0 disabled:text-black disabled:text-opacity-60',
            'after:content-[" "] after:absolute after:border-r-2 after:border-b-2 after:h-3 after:w-2 after:rotate-45 after:border-primary after:opacity-0 checked:after:opacity-100',
            'relative mr-2.5 flex cursor-pointer bg-transparent ',
            'checked:bg-transparent checked:bg-none hover:bg-transparent checked:hover:border-current checked:hover:bg-transparent checked:focus:border-current checked:focus:bg-transparent',
            'focus:ring-1 focus:ring-offset-0',
            'after:left-1/2 after:top-1/4 after:-translate-x-1/2 after:-translate-y-1/4'
          )}
          name={name}
          ref={ref}
          {...rest}
        />
        <span className="text-sm">{label}</span>
      </label>
      {error && (
        <span className="mt-[0.0625rem] text-center text-xxs text-red">
          {error}
        </span>
      )}
    </>
  )
);
