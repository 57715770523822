import { cva, VariantProps } from 'class-variance-authority';
import React, { FC, ReactNode } from 'react';
import { cn } from '../helpers/cn';
import { Link, LinkProps } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../assets/icons/arrow-right.svg';

const card = cva(
  'relative p-4 transition rounded-2xl hover:scale-105 ease-in-out duration-300 pr-10',
  {
    variants: {
      variant: {
        primary: 'bg-primary-100',
        secondary: 'bg-dodger-blue/20',
        tertiary: 'bg-gray-50',
        quaternary: 'bg-carrot-orange/20',
        quinary: 'bg-dodger-blue/10',
        outline: 'border border-primary border-2 text-primary',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
);

export type CardProps = LinkProps &
  VariantProps<typeof card> & { icon?: ReactNode };

export type CardHeaderProps = {
  className?: string;
  title: string;
  description?: string;
};

export const Card: FC<CardProps> = ({
  children,
  className,
  icon = <ArrowRight className="text-santas-gray " />,
  variant,
  ...rest
}) => {
  return (
    <Link {...rest} className={cn(card({ variant }), className)}>
      {children}
      {icon && <div className="absolute right-5 bottom-10">{icon}</div>}
    </Link>
  );
};

export const CardHeader: FC<CardHeaderProps> = ({
  className,
  title,
  description,
}) => {
  return (
    <div className={cn('', className)}>
      <h2 className="text-xl mb-2">{title}</h2>
      {description && <p className="text-sm text-black/60">{description}</p>}
    </div>
  );
};
