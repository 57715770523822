import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { listBenefits, Benefit } from '../api/benefits';
import { Button } from '../components/Button';
import { ReactComponent as Plus } from '../assets/icons/plus.svg';
import { Loader } from '../components/Loader';

const BenefitListPage: React.FC = () => {
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBenefits();
  }, []);

  const fetchBenefits = async () => {
    try {
      setLoading(true);
      const data = await listBenefits();
      setBenefits(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch benefits');
      console.error('Error fetching benefits:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateBenefit = () => {
    navigate('/benefits/new');
  };

  const handleUpdateBenefit = (id: string) => {
    navigate(`/benefits/${id}`);
  };

  const formatWeekdays = (weekdays: number[]): string => {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return weekdays.map((day) => days[day - 1]).join(', ');
  };

  if (loading)
    return (
      <div className="loading-page">
        <Loader />
      </div>
    );
  if (error) return <div className="error">{error}</div>;

  return (
    <div>
      <div className="flex max-md:flex-col md:items-center md:justify-between mb-20 max-md:gap-4">
        <h2 className="font-medium">Benefits List</h2>
        <Button onClick={handleCreateBenefit} variant="light" className="gap-2">
          Create new benefit
          <Plus />
        </Button>
      </div>
      <div className="overflow-x-auto">
        <table className="table-default">
          <thead>
            <tr>
              <th>Name</th>
              <th>Total Cashback</th>
              <th>Customer Cashback</th>
              <th>Start Date Time</th>
              <th>End Date Time</th>
              <th>Weekdays</th>
              <th>Blacklist Amounts</th>
              <th>Whitelist Amounts</th>
              <th>Take Amount Above</th>
              <th>Take Amount Below</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {benefits.map((benefit) => (
              <tr key={benefit.id}>
                <td>{benefit.name}</td>
                <td>{benefit.totalCashbackValue}%</td>
                <td>{benefit.customerCashbackValue}%</td>
                <td>{new Date(benefit.startDateTime).toLocaleString()}</td>
                <td>
                  {benefit.endDateTime
                    ? new Date(benefit.endDateTime).toLocaleString()
                    : 'N/A'}
                </td>
                <td>{formatWeekdays(benefit.weekdays)}</td>
                <td>{benefit.blacklistAmount.join(', ')}</td>
                <td>{benefit.whitelistAmount.join(', ')}</td>
                <td>{benefit.takeAmountAbove || 'N/A'}</td>
                <td>{benefit.takeAmountBelow || 'N/A'}</td>
                <td>{benefit.startTime}</td>
                <td>{benefit.endTime || 'N/A'}</td>
                <td>
                  <Button
                    variant="outline"
                    onClick={() => handleUpdateBenefit(benefit.id)}
                    className="h-6 text-xs font-normal py-1 px-9"
                  >
                    Update
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BenefitListPage;
