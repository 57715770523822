import api from './axiosConfig';
import { Image } from './common';
import { CreateIdResponse } from './common';

export const uploadImage = async (file: File): Promise<CreateIdResponse> => {
  const formData = new FormData();
  formData.append('File', file);
  formData.append('Title', file.name);

  try {
    const response = await api.post(
      '/business-account/upload-image',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw new Error('Failed to upload image');
  }
};

export const getImage = async (assetId: string): Promise<Image> => {
  try {
    const response = await api.post('/business-account/get-image', {
      AssetId: assetId,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching image:', error);
    throw new Error('Failed to fetch image');
  }
};
