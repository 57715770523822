export const ROUTES = {
  HOME: () => '/',
  LOGIN: () => '/login',
  PROFILE: () => '/profile',
  RESET_PASSWORD: () => '/reset-password',
  REQUEST_PASSWORD_RESET: () => '/request-password-reset',
  SIGNUP: () => '/signup',
  BENEFITS: () => '/benefits',
  BUSINESSES: () => '/businesses',
  SETTINGS: () => '/settings',
  BENEFITS_NEW: () => '/benefits/new',
  BENEFITS_ID: () => '/benefits/:id',
  BUSINESSES_NEW: () => '/businesses/new',
  BUSINESSES_ID: () => '/businesses/:id',
  TRANSACTIONS: () => '/transactions',
  INSIGHTS: () => '/insights',
  TERMS_OF_SERVICE: () => '/terms-of-service',
};
