import api from './axiosConfig';
import { jwtDecode } from 'jwt-decode';

export interface User {
  id: string;
  email: string;
}

export interface JwtPayload {
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier': string;
  'exp': number
}

export interface LoginCredentials {
  Email: string;
  Password: string;
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

export const isTokenValid = (accessToken: string): boolean => {
  const decodedToken = jwtDecode<JwtPayload>(accessToken);
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return decodedToken.exp + 10 > currentTimestamp;
}


export const refreshAccessToken = async () => {
  try {
    const response = await api.post<LoginResponse>('/business-account/refresh-access-token');
  } catch (error) {
    console.error('Refreshing token:', error);
    throw error;
  }
};

export const login = async (credentials: LoginCredentials) => {
  try {
    const response = await api.post<LoginResponse>('/business-account/login', credentials);

  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const getMyself = async (): Promise<User> => {
  try {
    const response = await api.post<User>('/business-account/get-myself', {});
    return response.data;
    
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await api.post('/business-account/logout');

  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
};

export const requestPasswordReset = async (email: string): Promise<void> => {
  try {
    const response = await api.post('/business-account/request-password-reset', {
      email: email
    });
    
  } catch (error) {
    console.error('Request password reset error:', error);
    throw error;
  }
};

export const resetPassword = async (token: string, password: string): Promise<void> => {
  try {
    const response = await api.post('/business-account/reset-password', {
      token: token,
      newPassword: password
    });
    
  } catch (error) {
    console.error('Reset password error:', error);
    throw error;
  }
};