import React, { FC, PropsWithChildren } from 'react';
import { cn } from '../helpers/cn';

export type LabelProps = PropsWithChildren<{
  className?: string;
  additionalText?: string;
}>;

export const Label: FC<LabelProps> = ({
  additionalText,
  children,
  className,
}) => {
  return (
    <span className={cn('text-sm', className)}>
      {children}
      {additionalText && (
        <span className="text-black/60 ml-1">{additionalText}</span>
      )}
    </span>
  );
};
