import React, { useState, useEffect } from "react";
import { getInsights } from "../api/insights";
import { Loader } from "../components/Loader";
import { CompanyInsights, GlobalInsights } from "../api/insights";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  Legend,
} from "recharts";
import { AgeGroup, GenderGroup } from "../api/insights";

const BAR_COLORS = [
  "rgb(99 102 241 / 0.7)",
  "rgb(236 72 153 / 0.7)",
  "rgb(34 197 94 / 0.7)",
  "rgb(245 158 11 / 0.7)",
  "rgb(139 92 246 / 0.7)",
  "rgb(6 182 212 / 0.7)",
];

const PIE_COLORS = {
  Male: "rgb(99 102 241 / 0.7)",
  Female: "rgb(236 72 153 / 0.7)",
  Other: "rgb(34 197 94 / 0.7)", // green color for "Other"
  NotSelected: "rgb(209 213 219 / 0.7)", // light gray
};

const Insights: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [insights, setInsights] = useState<CompanyInsights | null>(null);
  const [globalInsights, setGlobalInsights] = useState<GlobalInsights | null>(
    null
  );

  useEffect(() => {
    fetchInsights();
  }, []);

  const fetchInsights = async () => {
    try {
      setLoading(true);
      const response = await getInsights();
      setInsights(response.companyInsights);
      setGlobalInsights(response.globalInsights);
      setError(null);
    } catch (err) {
      setError("Failed to fetch insights");
      console.error("Error fetching insights:", err);
    } finally {
      setLoading(false);
    }
  };

  const prepareChartData = (data: {
    ageGroups: AgeGroup[];
    genderGroups: GenderGroup[];
  }) => {
    const ageData = data.ageGroups.map((group) => {
      const total = data.ageGroups.reduce(
        (sum, g) => sum + g.customersCount,
        0
      );
      const percentage =
        total === 0 ? "0.0" : ((group.customersCount / total) * 100).toFixed(1);
      return {
        name: group.ageRange,
        value: group.customersCount,
        label: `${percentage}%`,
      };
    });

    const genderData = data.genderGroups.map((group) => {
      const total = data.genderGroups.reduce(
        (sum, g) => sum + g.customersCount,
        0
      );
      const percentage =
        total === 0 ? "0.0" : ((group.customersCount / total) * 100).toFixed(1);
      return {
        name: group.gender,
        value: group.customersCount,
        label: `${group.gender} ${percentage}%`,
        color: PIE_COLORS[group.gender as keyof typeof PIE_COLORS]
      };
    });

    return { ageData, genderData };
  };

  const renderCustomLegend = (data: any[]) => (
    <ul className="flex justify-center mt-4">
      {data.map((entry, index) => (
        <li key={`item-${index}`} className="mx-2">
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              backgroundColor: data[index].color,
              marginRight: "5px",
            }}
          />
          {entry.label}
        </li>
      ))}
    </ul>
  );

  if (loading)
    return (
      <div className="loading-page">
        <Loader />
      </div>
    );
  if (error) return <div className="error">{error}</div>;
  if (!insights || !globalInsights) return null;

  const partnerData = prepareChartData(insights);
  const globalData = prepareChartData(globalInsights);

  const InsightsSection = ({
    title,
    data,
    totalCount,
  }: {
    title: string;
    data: { ageData: any[]; genderData: any[] };
    totalCount: number;
  }) => (
    <>
      <div className="flex max-md:flex-col md:items-center md:justify-between mb-10 max-md:gap-4">
        <div>
          <h2 className="font-medium">{title}</h2>
          <p className="text-sm text-gray-500 mt-1">
            Data is refreshed approximately every hour
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4 text-center">
            Total Customers
          </h3>
          <div className="h-[300px] flex items-center justify-center">
            <div className="w-40 h-40 rounded-full border-[12px] border-[#6366F1]/20 flex items-center justify-center">
              <span className="text-4xl font-bold text-[#6366F1]/60">
                {totalCount.toLocaleString()}
              </span>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4 text-center">
            Age Distribution
          </h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data.ageData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <Tooltip />
                <Bar dataKey="value" fill="#8884d8">
                  {data.ageData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={BAR_COLORS[index % BAR_COLORS.length]}
                    />
                  ))}
                  <LabelList dataKey="label" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4 text-center">
            Gender Distribution
          </h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={data.genderData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  isAnimationActive={false}
                  labelLine={false}
                >
                  {data.genderData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend content={() => renderCustomLegend(data.genderData)} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      <h2 className="font-medium mb-8">Insights</h2>
      <InsightsSection
        title="Your Customers"
        data={partnerData}
        totalCount={insights.totalCustomersCount}
      />
      <InsightsSection
        title="All OisterBonus Customers"
        data={globalData}
        totalCount={globalInsights.totalCustomersCount}
      />
    </div>
  );
};

export default Insights;
