import React, { FC, PropsWithChildren } from 'react';
import Navigation from '../Navigation';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ROUTES } from '../../config/routes';
import { Link } from 'react-router-dom';

export const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="bg-white flex flex-col lg:flex-row h-screen">
      <aside className="w-full flex flex-col lg:sticky lg:w-1/3 lg:max-w-[300px] lg:border-r lg:border-primary/30 lg:h-full p-4">
        <Link to={ROUTES.HOME()} className="mb-10 lg:mb-24">
          <Logo />
        </Link>
        <Navigation />
      </aside>
      <section className="container pt-4 pb-10 lg:py-10">{children}</section>
    </div>
  );
};
