import React from 'react';
import { useAuth } from '../context/AuthContext';
import { ROUTES } from '../config/routes';
import { Button } from './Button';
import { ReactComponent as Exit } from '../assets/icons/exit.svg';
import { NavigationLink } from './NavigationLink';

const Navigation: React.FC = () => {
  const { logout } = useAuth();

  return (
    <div className="flex-1">
      <ul className="flex flex-col gap-2 h-full">
        <li>
          <NavigationLink to={ROUTES.HOME()}>Dashboard</NavigationLink>
        </li>
        <li>
          <NavigationLink to={ROUTES.TRANSACTIONS()}>Transactions</NavigationLink>
        </li>
        <li>
          <NavigationLink to={ROUTES.INSIGHTS()}>Insights</NavigationLink>
        </li>
        <li>
          <NavigationLink to={ROUTES.PROFILE()}>Profile</NavigationLink>
        </li>
        <li>
          <NavigationLink to={ROUTES.BENEFITS()}>Benefits</NavigationLink>
        </li>
        <li>
          <NavigationLink to={ROUTES.BUSINESSES()}>Business</NavigationLink>
        </li>
        <li>
          <NavigationLink to={ROUTES.TERMS_OF_SERVICE()}>Terms of Service</NavigationLink>
        </li>
        
        {/* <li>
          <NavigationLink to={ROUTES.SETTINGS()}>Settings</NavigationLink>
        </li> */}
        <li className="mt-auto">
          <Button
            variant="ghost"
            onClick={logout}
            className="text-red gap-2 flex items-center hover:text-red-400"
          >
            <Exit />
            Log out
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default Navigation;
