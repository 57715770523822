import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { listBusinesses } from '../api/business';
import { Button } from '../components/Button';
import { ReactComponent as Plus } from '../assets/icons/plus.svg';
import { Loader } from '../components/Loader';

interface Business {
  id: string;
  name: string;
  businessCategories: { id: string; name: string }[];
}

const BusinessList: React.FC = () => {
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const data = await listBusinesses();
        setBusinesses(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch businesses');
        setLoading(false);
      }
    };

    fetchBusinesses();
  }, []);

  const handleCreateBusiness = () => {
    navigate('/businesses/new');
  };

  const handleUpdateBusiness = (id: string) => {
    navigate(`/businesses/${id}`);
  };

  if (loading)
    return (
      <div className="loading-page">
        <Loader />
      </div>
    );
  if (error) return <div className="error">{error}</div>;

  return (
    <div>
      <div className="flex max-md:flex-col md:items-center md:justify-between mb-20 max-md:gap-4">
        <h2 className="font-medium">Business List</h2>
        <Button
          onClick={handleCreateBusiness}
          variant="light"
          className="gap-2"
        >
          Create New Business
          <Plus />
        </Button>
      </div>
      <table className="table-default">
        <thead>
          <tr>
            <th>Name</th>
            <th>Business Categories</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {businesses.map((business) => (
            <tr key={business.id}>
              <td>{business.name}</td>
              <td>
                {business.businessCategories.map((cat) => cat.name).join(', ')}
              </td>
              <td>
                <Button
                  onClick={() => handleUpdateBusiness(business.id)}
                  variant="outline"
                  className="h-6 text-xs font-normal py-1 px-9"
                >
                  Update
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BusinessList;
