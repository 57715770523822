import React, { forwardRef } from 'react';
import { TextField, TextFieldProps } from './TextField';
import { cn } from '../helpers/cn';

export const TextArea = forwardRef<
  HTMLTextAreaElement,
  TextFieldProps<'textarea'>
>(({ as, className, ...rest }, ref) => (
  <TextField ref={ref} as="textarea" className={cn('', className)} {...rest} />
));
