import api from "./axiosConfig";

// Add these interfaces
export interface TermsOfService {
  title: string;
  version: number;
  content: string;
}

export interface TermsOfServiceResponse {
  termsOfService: TermsOfService | null;
  accepted: boolean;
}

// Add this function to get terms of service
export const getTermsOfService = async (): Promise<TermsOfServiceResponse> => {
  try {
    const response = await api.post('/business-account/get-terms-of-service', {});
    return response.data;
  } catch (error) {
    console.error('Getting terms of service error:', error);
    throw error;
  }
};

export const acceptTermsOfService = async (termsOfServiceVersion: number): Promise<void> => {
  try {
    await api.post('/business-account/accept-terms-of-service', { termsOfServiceVersion: termsOfServiceVersion });
  } catch (error) {
    console.error('Accepting terms of service error:', error);
    throw error;
  }
};
