import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createBenefit,
  updateBenefit,
  CreateBenefitInput,
  UpdateBenefitInput,
  getBenefit,
} from '../api/benefits';
import { TextField } from './TextField';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { getWeekdays } from '../helpers/getWeekdays';
import { Label } from './Label';

type BenefitFormState = Omit<
  CreateBenefitInput,
  | 'totalCashbackValue'
  | 'customerCashbackValue'
  | 'takeAmountAbove'
  | 'takeAmountBelow'
  | 'blacklistAmount'
  | 'whitelistAmount'
  | 'startTime'
  | 'endTime'
  | 'endDateTime'
> & {
  id?: string;
  totalCashbackValue: string;
  customerCashbackValue: string;
  takeAmountAbove: string;
  takeAmountBelow: string;
  blacklistAmount: string;
  whitelistAmount: string;
  startTime: string;
  endTime: string;
  endDateTime: string;
};

const BenefitForm: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [input, setInput] = useState<BenefitFormState>({
    name: '',
    totalCashbackValue: '',
    customerCashbackValue: '',
    startDateTime: '',
    endDateTime: '',
    weekdays: [],
    blacklistAmount: '',
    whitelistAmount: '',
    takeAmountAbove: '',
    takeAmountBelow: '',
    startTime: '',
    endTime: '',
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const isUpdating = !!id;

  useEffect(() => {
    if (isUpdating) {
      getBenefit(id).then((benefit) => {
        setInput({
          ...benefit,
          totalCashbackValue: benefit.totalCashbackValue.toString(),
          customerCashbackValue: benefit.customerCashbackValue.toString(),
          takeAmountAbove: benefit.takeAmountAbove?.toString() || '',
          takeAmountBelow: benefit.takeAmountBelow?.toString() || '',
          blacklistAmount: benefit.blacklistAmount.join(', '),
          whitelistAmount: benefit.whitelistAmount.join(', '),
          startTime: benefit.startTime
            ? formatTimeForInput(benefit.startTime)
            : '',
          endTime: benefit.endTime ? formatTimeForInput(benefit.endTime) : '',
          startDateTime: formatDateForInput(benefit.startDateTime),
          endDateTime: benefit.endDateTime
            ? formatDateForInput(benefit.endDateTime)
            : '',
        });
      });
    }
  }, [id, isUpdating]);

  const formatTimeForInput = (timeString: string): string => {
    if (!timeString) return '';

    const [hours, minutes] = timeString.split(':');

    return `${hours}:${minutes}`;
  };

  const formatDateForInput = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 16);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === 'weekdays') {
      // check if value is already in array then remove
      const weekdays = input.weekdays
        .map((day) => day.toString())
        .includes(value)
        ? input.weekdays.filter((day) => day.toString() !== value)
        : [...input.weekdays, value];

      setInput((prev) => ({ ...prev, weekdays: weekdays.map(Number) }));
    } else {
      setInput((prev) => ({ ...prev, [name]: value }));
    }
  };

  const formatTime = (time: string): string | null => {
    return time ? `${time}:00` : null;
  };

  const parseNumberList = (value: string): number[] => {
    if (value === '') {
      return [];
    }
    return value
      .split(',')
      .map((v) => Number(v.trim()))
      .filter((v) => !isNaN(v));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const submitData: CreateBenefitInput = {
        ...input,
        totalCashbackValue: Number(input.totalCashbackValue),
        customerCashbackValue: Number(input.customerCashbackValue),
        takeAmountAbove: input.takeAmountAbove
          ? Number(input.takeAmountAbove)
          : null,
        takeAmountBelow: input.takeAmountBelow
          ? Number(input.takeAmountBelow)
          : null,
        blacklistAmount: parseNumberList(input.blacklistAmount),
        whitelistAmount: parseNumberList(input.whitelistAmount),
        endDateTime: input.endDateTime || null,
        startTime: input.startTime ? formatTime(input.startTime) : null,
        endTime: input.endTime ? formatTime(input.endTime) : null,
      };
      if (isUpdating) {
        await updateBenefit({ ...submitData, id } as UpdateBenefitInput);
      } else {
        await createBenefit(submitData as CreateBenefitInput);
      }
      navigate('/benefits');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-6">
        <h2 className="mb-4 font-medium">
          {isUpdating ? 'Update Benefit' : 'Create New Benefit'}
        </h2>
      </div>

      <form
        onSubmit={handleSubmit}
        className="bg-gray-50 p-8 grid lg:grid-cols-2 gap-6 rounded-2xl"
      >
        <TextField
          type="text"
          id="name"
          label={<Label additionalText="(required)">Name</Label>}
          name="name"
          value={input.name}
          onChange={handleChange}
          required
        />

        <TextField
          type="number"
          id="totalCashbackValue"
          name="totalCashbackValue"
          label="Total Cashback Value (%)"
          value={input.totalCashbackValue}
          onChange={handleChange}
          required
          min="0"
          step="0.01"
        />

        <TextField
          type="number"
          id="customerCashbackValue"
          name="customerCashbackValue"
          label="Customer Cashback Value (%)"
          value={input.customerCashbackValue}
          onChange={handleChange}
          required
          min="0"
          step="0.01"
        />

        <TextField
          type="datetime-local"
          id="startDateTime"
          name="startDateTime"
          label="Start Date Time"
          value={input.startDateTime}
          onChange={handleChange}
          required
        />

        <TextField
          label={<Label additionalText="(optional)">End Date Time</Label>}
          type="datetime-local"
          id="endDateTime"
          name="endDateTime"
          value={input.endDateTime}
          onChange={handleChange}
        />

        <div className="flex flex-col">
          <span className="mb-3 block text-sm">Weekdays</span>
          <div className="flex flex-wrap gap-3 capitalize">
            {getWeekdays('en-US').map((w, i) => {
              return (
                <Checkbox
                  key={w}
                  value={i}
                  label={w}
                  name="weekdays"
                  onChange={handleChange}
                />
              );
            })}
          </div>
        </div>

        <TextField
          type="text"
          id="blacklistAmount"
          name="blacklistAmount"
          label={
            <Label additionalText="(comma-separated)">Blacklist Amounts</Label>
          }
          value={input.blacklistAmount}
          onChange={handleChange}
        />

        <TextField
          type="text"
          id="whitelistAmount"
          name="whitelistAmount"
          label={
            <Label additionalText="(comma-separated)">Whitelist Amounts</Label>
          }
          value={input.whitelistAmount}
          onChange={handleChange}
        />
        <TextField
          type="number"
          id="takeAmountAbove"
          name="takeAmountAbove"
          label={<Label additionalText="(or equal)">Take Amount Above</Label>}
          value={input.takeAmountAbove}
          onChange={handleChange}
          min="0"
          step="1"
        />

        <TextField
          type="number"
          id="takeAmountBelow"
          name="takeAmountBelow"
          label={<Label additionalText="(or equal)">Take Amount Below</Label>}
          value={input.takeAmountBelow}
          onChange={handleChange}
          min="0"
          step="1"
        />

        <TextField
          type="time"
          id="startTime"
          name="startTime"
          label={<Label additionalText="(optional)">Start Time</Label>}
          value={input.startTime}
          onChange={handleChange}
        />
        <TextField
          type="time"
          id="endTime"
          name="endTime"
          label={<Label additionalText="(optional)">End Time</Label>}
          value={input.endTime}
          onChange={handleChange}
        />

        <Button
          type="submit"
          size="lg"
          className="w-1/2 whitespace-nowrap"
          isLoading={loading}
        >
          {isUpdating ? 'Save changes' : 'Create benefit'}
        </Button>
      </form>
      {error && <div className="absolute mt-4 text-red-500">{error}</div>}
    </div>
  );
};

export default BenefitForm;
