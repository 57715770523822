import api from './axiosConfig';
import { CreateIdResponse } from './common'

export interface ExternalLinks {
    id: string;
    name: string;
    facebook: string | undefined;
    instagram: string | undefined;
    bookingLink: string | undefined;
    customLinks: ExternalLinksCustom[];
  }

  export interface ExternalLinksCustom {
    id: string;
    title: string;
    url: string;
  }

  export interface CreateExternalLinkInput {
    name: string;
    facebook: string | undefined;
    instagram: string | undefined;
    bookingLink: string | undefined;
    customLinksIds: string[];
  }

  export interface CreateExternalLinksCustomInput{
    title: string;
    url: string;
  }

  export const listExternalLinks = async (): Promise<ExternalLinks[]> => {
    try {
      const response = await api.post('business-account/list-external-links', {});
      return response.data;
    } catch (error) {
      console.error('Listing external links error:', error);
      throw error;
    }
  };

  export const getExternalLinks = async (id: string): Promise<ExternalLinks> => {
    try {
      const response = await api.post('business-account/get-external-links', {
        externalLinksId: id
      });
      return response.data;
    } catch (error) {
      console.error('Getting external links error:', error);
      throw error;
    }
  };

  export const createExternalLink = async (input: CreateExternalLinkInput): Promise<CreateIdResponse> => {
    try {
      const response = await api.post('/business-account/create-or-update-external-links', {
        createOrUpdateExternalLinksInput: input
      });
      return response.data;
    } catch (error) {
      console.error('Creating external link error:', error);
      throw error;
    }
  };


  export const updateExternalLink = async (id: string, input: CreateExternalLinkInput) => {
    try {
      const response = await api.post('/business-account/create-or-update-external-links', {
        createOrUpdateExternalLinksInput: { ...input, Id: id }
      });
      return response.data;
    } catch (error) {
      console.error('Updating external link error:', error);
      throw error;
    }
  };


  export const createExternalLinkCustom = async (input: CreateExternalLinksCustomInput): Promise<CreateIdResponse> => {
    try {
      const response = await api.post('/business-account/create-or-update-custom-links', {
        createOrUpdateCustomLinksInput: input
      });
      return response.data;
    } catch (error) {
      console.error('Creating external link custom error:', error);
      throw error;
    }
  };

  export const updateExternalLinkCustom = async (id: string, input: CreateExternalLinksCustomInput): Promise<CreateIdResponse> => {
    try {
      const response = await api.post('/business-account/create-or-update-custom-links', {
        createOrUpdateCustomLinksInput: { ...input, Id: id }
      });
      return response.data;
    } catch (error) {
      console.error('Updating external link custom error:', error);
      throw error;
    }
  };

  export const listExternalLinksCustom = async (): Promise<ExternalLinksCustom[]> => {
    try {
      const response = await api.post('business-account/list-custom-links', {});
      return response.data;
    } catch (error) {
      console.error('Listing custom links error:', error);
      throw error;
    }
  };

  export const getExternalLinksCustom = async (id: string): Promise<ExternalLinksCustom> => {
    try {
      const response = await api.post('business-account/get-external-links-custom', {
        ExternalLinksCustomId: id
      });
      return response.data;
    } catch (error) {
      console.error('Getting external links error:', error);
      throw error;
    }
  };