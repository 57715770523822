import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import {
  updateExternalLink,
  createExternalLink,
  getExternalLinks,
  getExternalLinksCustom,
} from '../api/externalLinks';
import CustomLinksForm from './CustomLinksForm';
import { Button } from './Button';
import { TextField } from './TextField';
import { ReactComponent as CloseIcon } from '../assets/icons/x.svg';
import { v4 as uuidv4 } from 'uuid';

type ExternalinksFormState = {
  name: string;
  facebook: string | undefined;
  instagram: string | undefined;
  bookingLink: string | undefined;
  customLinks: {
    id: string;
    title: string;
  }[];
};

type ExternalLinksFormProps = {
  id?: string;
  onSubmit: (id: string) => Promise<void>;
  onCancel: () => void;
};

const ExternalinksForm: React.FC<ExternalLinksFormProps> = ({
  id = null,
  onSubmit,
  onCancel,
}) => {
  const [input, setInput] = useState<ExternalinksFormState>({
    name: uuidv4(),
    facebook: undefined,
    instagram: undefined,
    bookingLink: undefined,
    customLinks: [],
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingLinkId, setEditingLinkId] = useState<string | undefined>(
    undefined
  );
  const [error, setError] = useState<string | null>(null);

  const isUpdating = !!id;

  useEffect(() => {
    const fetchData = async () => {
      if (!isUpdating) return;
      try {
        const data = await getExternalLinks(id!);
        setInput({
          name: data.name || '',
          facebook: data.facebook || undefined,
          instagram: data.instagram || undefined,
          bookingLink: data.bookingLink || undefined,
          customLinks: data.customLinks || [],
        });
        setError(null);
      } catch (err) {
        setError('Failed to fetch External Links data');
        console.error('Error fetching External Links data:', err);
      }
    };

    fetchData();
  }, [id, isUpdating]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleRemoveCustomLink = (linkId: string) => {
    setInput((prev) => ({
      ...prev,
      customLinks: prev.customLinks.filter((link) => link.id !== linkId),
    }));
  };

  const handleCustomLinkAdd = async (newCustomLink: { id: string }) => {
    const data = await getExternalLinksCustom(newCustomLink.id);
    setInput((prev) => ({
      ...prev,
      customLinks: prev.customLinks.concat({ ...data }),
    }));
  };

  const handleCustomLinkUpdate = async (customLink: { id: string }) => {
    const data = await getExternalLinksCustom(customLink.id);

    setInput((prev) => ({
      ...prev,
      customLinks: prev.customLinks.map((link) =>
        link.id === data.id ? { ...data } : link
      ),
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (id) {
        const response = await updateExternalLink(id, {
          ...input,
          customLinksIds: input.customLinks.map((cl) => cl.id),
        });
        await onSubmit(response.id);
      } else {
        const response = await createExternalLink({
          ...input,
          customLinksIds: input.customLinks.map((cl) => cl.id),
        });
        await onSubmit(response.id);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleOpenAddModal = () => {
    setEditingLinkId(undefined);
    setIsModalOpen(true);
  };

  const handleOpenEditModal = (linkId: string) => {
    setEditingLinkId(linkId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingLinkId(undefined);
  };

  if (error) return <div>{error}</div>;

  return (
    <div className="">
      <Modal
        isOpen={isModalOpen}
        className="modal-content min-h-[37.5rem]"
        overlayClassName="modal-overlay"
        onRequestClose={handleCloseModal}
        contentLabel={editingLinkId ? 'Edit Custom Link' : 'Add Custom Link'}
      >
        <div className="flex justify-between mb-6">
          <h2 className="text-primary font-medium text-center">
            {editingLinkId ? 'Edit Custom Link' : 'Add Custom Link'}
          </h2>
          <Button
            onClick={handleCloseModal}
            variant="icon"
            className="bg-transparent text-primary"
          >
            <CloseIcon className="w-4 h-4 text-primary" />
          </Button>
        </div>
        <CustomLinksForm
          id={editingLinkId}
          onSubmit={async (linkId) => {
            if (editingLinkId) {
              await handleCustomLinkUpdate({ id: linkId });
            } else {
              await handleCustomLinkAdd({ id: linkId });
            }
            handleCloseModal();
          }}
          onCancel={handleCloseModal}
        />
      </Modal>

      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <TextField
          label="Facebook"
          id="facebook"
          name="facebook"
          type="url"
          pattern="https://.*"
          value={input.facebook}
          onChange={handleChange}
        />

        <TextField
          type="url"
          id="instagram"
          pattern="https://.*"
          name="instagram"
          label="Instagram"
          value={input.instagram}
          onChange={handleChange}
        />

        <TextField
          type="url"
          id="bookingLink"
          pattern="https://.*"
          name="bookingLink"
          label="Booking Link"
          value={input.bookingLink}
          onChange={handleChange}
        />

        <div className="">
          <div className="flex items-center justify-between mb-4">
            <p className="font-medium">Custom Links</p>
            <Button type="button" onClick={handleOpenAddModal}>
              Add New Custom Link
            </Button>
          </div>

          <div className="flex flex-col gap-4">
            {input.customLinks.map((link) => (
              <div key={link.id} className="flex justify-between items-center ">
                <p className="flex flex-wrap">{link.title}</p>
                <div className="flex gap-2">
                  <Button
                    type="button"
                    onClick={() => handleOpenEditModal(link.id)}
                    size="sm"
                  >
                    Edit
                  </Button>
                  <Button
                    type="button"
                    variant="light"
                    size="sm"
                    onClick={() => handleRemoveCustomLink(link.id)}
                    className="btn btn-danger"
                  >
                    Remove
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Button type="submit" className="btn">
          {id ? 'Update' : 'Create'}
        </Button>
        <Button
          type="button"
          className="btn btn-secondary"
          onClick={handleCancel}
          variant="light"
        >
          Cancel
        </Button>
      </form>
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
};

export default ExternalinksForm;
