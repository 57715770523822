import axios from 'axios';
import { isTokenValid, refreshAccessToken } from './auth'
import { logout } from '../api/auth'

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

const AnonymousPaths = [
    '/business-account/login',
    '/business-account/refresh-access-token',
    '/business-account/request-password-reset',
    '/business-account/reset-password'
]

const isPublicPath = (path: string): boolean => {
    const publicPaths = ['/login', '/register', '/reset-password', '/request-password-reset', '/'];
    return publicPaths.includes(path);
};

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true
});

let isRefreshing = false;
let refreshSubscribers: Array<(token: string) => void> = [];

const addRefreshSubscriber = (callback: (token: string) => void) => {
    refreshSubscribers.push(callback);
};

const onRefreshed = () => {
    refreshSubscribers.forEach(callback => callback(''));
    refreshSubscribers = [];
};

api.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response?.status !== 401 || originalRequest._retry) {
            return Promise.reject(error);
        }

        if (originalRequest.url === '/business-account/refresh-access-token') {

            window.location.href = '/login';
            return Promise.reject(error);
        }

        if (!isRefreshing) {

            isRefreshing = true;
            originalRequest._retry = true;

            try {
                await refreshAccessToken();
                isRefreshing = false;
                onRefreshed();
                
                return api(originalRequest);
            } catch (refreshError) {
                isRefreshing = false;
                if (!isPublicPath(window.location.pathname)){
                    window.location.href = '/login';
                }
                return Promise.reject(refreshError);
            }
        }

        return new Promise(resolve => {
            addRefreshSubscriber(() => {
                resolve(api(originalRequest));
            });
        });
    }
);

export default api;