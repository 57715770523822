import React, {
  forwardRef,
  ElementType,
  ComponentProps,
  Ref,
  ReactNode,
} from 'react';
import { cn } from '../helpers/cn';

type BaseProps<E extends ElementType = ElementType> = {
  as?: E;
  label?: ReactNode;
  error?: string;
  className?: string;
};

export type TextFieldProps<E extends ElementType> = BaseProps<E> &
  Omit<ComponentProps<E>, keyof BaseProps>;

//@ts-ignore
export const TextField: <E extends ElementType = 'input'>(
  props: TextFieldProps<E>
) => JSX.Element | null = forwardRef<Ref<Element>, BaseProps>(
  ({ as = 'input', className, error, label, ...props }, ref) => {
    const Component = as;
    return (
      <label className="relative flex flex-col">
        {label && <span className="mb-0.5 block text-sm">{label}</span>}
        <Component
          ref={ref}
          className={cn(
            'flex rounded-2xl border border-gray-300 bg-transparent px-4 py-[0.8438rem] text-sm leading-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none  focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50',
            error && 'border-red-500 ',
            className
          )}
          {...props}
        />
        {error && (
          <span className="mt-1 absolute text-xs text-red-500">{error}</span>
        )}
      </label>
    );
  }
);

// @ts-ignore
TextField.displayName = 'TextField';
