import React, { FC, PropsWithChildren } from 'react';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { Outlet } from 'react-router-dom';
export type AuthLayoutProps = FC<PropsWithChildren>;

export const AuthLayout: AuthLayoutProps = ({ children }) => {
  return (
    <div className="bg-gray-50 h-screen w-full">
      <Logo className="absolute top-4 left-4 " />
      <div className="container h-full flex justify-center items-center">
        <Outlet />
      </div>
    </div>
  );
};
