import React from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

interface MapPickerProps {
  location: { lat: number; lng: number } | null;
  onChange: (location: { lat: number; lng: number }) => void;
}

const MapPicker: React.FC<MapPickerProps> = ({ location, onChange }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
    });

    const defaultCenter = { lat: 52.237049, lng: 21.017532 };

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps...</div>;
    }

    // Renderuj mapę tylko gdy jest załadowana
    return (
        <GoogleMap
            zoom={13}
            center={location || defaultCenter}
            mapContainerStyle={{ width: '100%', height: '400px' }}
            onClick={(e) => {
                if (e.latLng) {
                    onChange({
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng()
                    });
                }
            }}
        >
            {location && <Marker position={location} />}
        </GoogleMap>
    );
};

export default MapPicker;