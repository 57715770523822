import React, {
  forwardRef,
  useEffect,
  useState,
  SelectHTMLAttributes,
  ReactNode,
} from 'react';
import { cn } from '../helpers/cn';

export type Option = { label: string; value: string | null };

export type SelectProps<
  T = boolean | undefined,
  K = T extends true ? string[] : string | null
> = {
  multiple?: T;
  values: string[] | Option[];
  label?: ReactNode;
  error?: string;
  value: string | number | string[] | null;
  onChange: (value: K) => void;
  onClick?: () => void;
  required?: boolean;
} & Omit<
  SelectHTMLAttributes<HTMLSelectElement>,
  'onChange' | 'value' | 'multiple' | 'onClick'
>;

function SelectInner<T>(
  {
    values,
    name,
    label,
    id,
    className,
    value = null,
    error,
    onChange,
    onClick,
    multiple,
    required,
    ...rest
  }: SelectProps<T>,
  ref: React.ForwardedRef<HTMLSelectElement>
) {
  const valuesArray = values.map((v) =>
    typeof v === 'string' ? { value: v, label: v } : v
  );

  // eslint-disable-next-line no-nested-ternary
  const valueAsArray = (
    Array.isArray(value) ? value : value ? [value] : []
  ).map((v) => v.toString());
  const [selected, setSelected] = useState<Option[]>(
    valuesArray.filter((v) =>
      v.value ? valueAsArray.includes(v.value) : valueAsArray.length === 0
    )
  );

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  const handleOptionClick = (option: Option) => {
    const isSelected = selected?.some((s) => s.value === option.value);
    const newOptions = isSelected
      ? selected?.filter((s) => s.value !== option.value)
      : selected?.concat(option);
    setSelected(multiple ? newOptions : [option]);

    onChange?.(
      // @ts-ignore
      multiple
        ? (newOptions.map((o) => o.value).filter(Boolean) as string[])
        : option.value
    );
  };

  useEffect(() => {
    // Reset selected when form is reset
    if (valueAsArray.length === 0) {
      setSelected(valuesArray.filter((v) => v.value === null));
    } else {
      setSelected(
        valuesArray.filter((v) =>
          v.value ? valueAsArray.includes(v.value) : valueAsArray.length === 0
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(valueAsArray), JSON.stringify(valuesArray)]);

  return (
    <label
      htmlFor={id}
      className={cn('flex flex-col justify-center p-0', className)}
    >
      <div className="relative">
        {label && (
          <span
            className={cn(
              'text-sm mb-0.5',
              required && "after:text-red after:content-['_*']"
            )}
          >
            {label}
          </span>
        )}

        <div className="relative flex items-center font-bold text-black/50">
          <select
            {...rest}
            ref={ref}
            name={name}
            id={id}
            onChange={() => {}}
            multiple={multiple as boolean}
            value={multiple ? valueAsArray : valueAsArray[0] || ''}
            className={cn('hidden appearance-none')}
          >
            {valuesArray.map((option) => (
              <option
                key={option.value}
                value={option.value || undefined}
                hidden
              >
                {option.label}
              </option>
            ))}
          </select>

          <button
            type="button"
            className={cn('truncate uppercase', {
              'text-black': selected.length > 0 || value,
              'border-red': error,
            })}
            onClick={handleClick}
          >
            {!multiple && selected[0]?.label}
          </button>
        </div>

        <div className="p-2 border border-gray-300 rounded-2xl">
          <div
            className={cn(
              ' gap-1 flex flex-col max-h-28 overflow-y-auto thin-scrollbar'
            )}
            id={id}
          >
            {valuesArray.map((option) => (
              <button
                type="button"
                onClick={() => handleOptionClick(option)}
                key={option.value}
                className={cn(
                  'p-2 text-start text-xs mr-1 hover:bg-gray-100 rounded-md',
                  {
                    'bg-gray-100': selected.some(
                      (s) => s?.value === option.value
                    ),
                  }
                )}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>

        {error && (
          <span className="absolute -bottom-3.5 left-0 text-xxs text-red">
            {error}
          </span>
        )}
      </div>
    </label>
  );
}

export const Select = forwardRef(SelectInner) as <T>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLSelectElement> }
) => ReturnType<typeof SelectInner>;
