import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Button } from './Button';
import { cn } from '../helpers/cn';
import { useLocation } from 'react-router-dom';

export const NavigationLink: FC<LinkProps> = ({ children, to, ...rest }) => {
  const location = useLocation();
  const isActive =
    location.pathname === to ||
    (to.toString() !== '/' && location.pathname.includes(to.toString()));

  return (
    <Link to={to} {...rest}>
      <Button
        variant="secondary"
        size="full"
        className={cn('justify-start', {
          'bg-primary-300 text-primary': isActive,
        })}
      >
        {children}
      </Button>
    </Link>
  );
};
