import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { requestPasswordReset } from '../api/auth';
import { TextField } from './TextField';
import { Button } from './Button';
import { ROUTES } from '../config/routes';

const RequestPasswordResetForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (success) return;

      try {
        await requestPasswordReset(email);
        setSuccess(true);
        setError(null);
      } catch (error) {
        console.error('Password reset request error:', error);
        setError('Failed to send password reset email. Please try again.');
        setSuccess(false);
      }
    },
    [email, success]
  );

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
      setError(null);
    },
    []
  );

  const handleBackToLogin = () => {
    navigate('/login');
  };

  if (success) {
    return (
      <div className="flex flex-col w-full p-4 gap-4 bg-white rounded-3xl md:max-w-[424px]">
        <h2 className="text-primary text-center font-semibold mb-8">
          Check Your Email
        </h2>
        <p className="mb-4">
          If an account exists for{' '}
          <span className="text-primary underline">{email}</span> <br /> you
          will receive a password reset email shortly.
        </p>
        <Button onClick={handleBackToLogin} className="submit-button">
          Back to Login
        </Button>
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col p-4 gap-4 bg-white rounded-3xl md:min-w-[424px]"
    >
      <h2 className="text-primary text-center font-semibold mb-8">
        Password Reset
      </h2>
      <p className="mb-6 text-sm">
        Please enter email associated with your account,
        <br />
        we will send you a link to password reset
      </p>
      <div className="mb-[86px]">
        <TextField
          type="email"
          label="Email"
          value={email}
          error={error || undefined}
          onChange={handleEmailChange}
          required
        />
      </div>
      <Button type="submit" size="lg">
        Send
      </Button>
      <Link to={ROUTES.LOGIN()} className="underline text-primary text-center">
        Back to Login
      </Link>
    </form>
  );
};

export default RequestPasswordResetForm;
